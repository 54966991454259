import parse from 'html-react-parser';
import moment from 'moment';
import React, { Component } from 'react';
import { confirmAlert } from 'react-confirm-alert';
import ReactQuill from 'react-quill';
import Select from 'react-select';
import { Button, Col, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap';
import ConfirmHelper from '../../helpers/ConfirmHelper';
import { fieldRequired } from '../../helpers/fieldRequired';
import { getCurrentUser } from '../../helpers/GetCurrentUser';
import { removeLoginDetails } from '../../helpers/Lookup';
import ThrobbleHelper from '../../helpers/ThrobbleHelper';
import { showToast } from '../../helpers/ToastHelper';

class ProgressTasks extends Component {

  constructor(props) {
    super(props);

    this.state = {
      CaseId: this.props.CaseId,
      progressData: [],
      id: 0,
      taskId: 0,
      IsOtherTask: false,
      taskOther: "",
      notes: "",
      fileWithId: 0,
      dateDue: "",
      progressModal: false,
      Status: 1,
      TaskOptions: this.props.TaskOptions,
      UserOptions: this.props.UserOptions
    }

    this.addProgress = this.addProgress.bind(this);
    this.onProgressTaskChange = this.onProgressTaskChange.bind(this);
    this.toggleProgress = this.toggleProgress.bind(this);
    this.saveProgress = this.saveProgress.bind(this);
    this.deleteProgressItem = this.deleteProgressItem.bind(this);
    this.deleteData = this.deleteData.bind(this);
  }

  componentDidMount() {
    this.loadData();
  }

  async loadUsers() {
    try {
      const response = await fetch('/api/userdata', {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${localStorage.getItem("token")}`
        }
      })

      if (response.ok) {
        const data = (await response.json()).sort((a, b) => a.firstName.localeCompare(b.firstName));

        const UserOptions = [{ value: 0, label: "- Select User - " }, ...data.map(item => { return { value: item.id, label: item.firstName + " " + item.lastName } })];
        this.setState({ UserOptions });
      }
    } catch (error) {
      console.error(error)
    }
  }


  async loadTasks() {
    try {
      const response = await fetch('/api/tasks', {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${localStorage.getItem("token")}`
        }
      })

      if (response.ok) {
        const data = (await response.json()).sort((a, b) => a.taskName.localeCompare(b.taskName));
        const TaskOptions = [{ value: 0, label: "- Select Task -" }, ...data.map(item => { return { value: item.id, label: item.taskName } })]
        this.setState({ TaskOptions });
        //console.log("tasks", data);
      }
    } catch (error) {
      console.error(error)
    }
  }


  async loadData() {
    try {
      const response = await fetch('/api/progress/listforcase/' + this.state.CaseId, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${localStorage.getItem("token")}`
        }
      });

      if (response.ok) {
        const data = await response.json();
        this.setState({ progressData: data });
      }
    } catch (error) {
      console.error(error)
    }
  }

  as

  toggleProgress() {
    this.setState({
      progressModal: !this.state.progressModal
    });
  }

  onProgressTaskChange(e) {
    const IsOtherTask = e.label.toLowerCase() === "other"
    this.setState({ taskId: e.value, IsOtherTask }, () => fieldRequired(e.value, "tbTaskIdError", "* Required"));
  }


  addProgress(Id) {
    let data = { id: 0, taskId: 0, IsOtherTask: false, taskOther: "", notes: "", fileWithId: 0, dateDue: "" };
    if (Id > 0) {
      data = this.state.progressData.find(c => c.id === Id);

      if (data) {

        const SelectedTask = this.state.TaskOptions.find(c => c.value === data.taskId);
        data.IsOtherTask = SelectedTask.label.toLowerCase() === "other";
      }
    }

    this.setState({ ...data, progressModal: true, DateAdded: data.dateAdded, DateModified: new Date(), Id: data.id });
  }

  render() {
    return (
      <>
        <Row className="border-bottom mt-4">
          <Col md={6}><h5>TASKS AND OUTCOMES</h5></Col>
          <Col md={6} className="text-end">
            <button className="btn btn-pastel-blue btn-sm" onClick={() => this.addProgress(0)}>Add Task <i className='fa fa-plus-circle ms-2'></i></button>
          </Col>
        </Row>


        <table className='table table-sm table-striped mt-2' >
          <thead className='bg-blue'>
            <tr>
              <th style={{ width: "8%" }}>Date</th>
              <th style={{ width: "20%" }}>Task</th>
              <th style={{ width: "36%" }}>Notes & Reasons</th>
              <th style={{ width: "18%" }}>File With</th>
              <th style={{ width: "8%" }}>Due Date</th>
              <th className="text-end" style={{ width: "10%" }}>Actions</th>
            </tr>
          </thead>
          <tbody>
            {this.state.progressData.length ?
              this.state.progressData.map((item, index) => {
                return <tr key={index}>
                  <td className="text-start">{moment(item.dateAdded).format("yyyy/MM/DD")}</td>
                  <td>{item.taskName}</td>
                  <td>{parse(item.notes)}</td>
                  <td>{item.fileWithName}</td>
                  <td>{moment(item.dateDue).format("yyyy/MM/DD")}</td>

                  <td className="text-end">
                    <i className='fa fa-edit me-2' title="Edit/View Case" onClick={() => this.addProgress(item.id)}></i>
                    <i className='fa fa-trash-alt me-2 text-danger' title="Edit/View Case" onClick={() => this.deleteProgressItem(item.id)}></i>
                  </td>
                </tr>
              })
              :
              <tr><td colSpan={6} className="text-center"><i>No progress logged yet...</i></td></tr>
            }
          </tbody>
        </table>



        <Modal isOpen={this.state.progressModal} toggle={this.toggleProgress} className={this.props.className} scrollable size="lg" backdrop="static">
          <ModalHeader toggle={this.toggleProgress} close={<button className="close" onClick={this.toggleProgress}><i className="fas fa-times"></i></button>}>Tasks & Outcomes</ModalHeader>
          <ModalBody>

            <div className='mb-3'>
              <Label>Task <span id='tbTaskIdError' className='error-message' /></Label>
              <Select
                className={"select2-sm"}
                options={this.state.TaskOptions}
                isSearchable={true}
                isClearable={false}
                backspaceRemovesValue={false}
                isRtl={false}
                value={this.state.TaskOptions.filter(option => option.value === this.state.taskId)}
                onChange={this.onProgressTaskChange = this.onProgressTaskChange.bind(this)}
              />

            </div>

            <div className='mb-3' id="pnlOther" hidden={!this.state.IsOtherTask}>
              <Label for='tbTaskOther'>Specify Other Task</Label>
              <input id='tbTaskOther' name='tbTaskOther' className='form-control form-control-sm' placeholder='Other task...' type='text' maxLength='500' value={this.state.taskOther} onChange={e => this.setState({ taskOther: e.target.value })} /> <div id='tbTaskOtherError' className='error-message' />
            </div>

            <div className='mb-3'>
              <Label for='tbNotes'>Notes & Reasons <span id='tbNotesError' className='error-message' /></Label>
              <ReactQuill id="tbNotes" theme="snow" value={this.state.notes} onChange={(e) => this.setState({ notes: e })} modules={this.quillModules} />
            </div>

            <div className="row">
              <div className='mb-3 col-md-8'>
                <Label>File With <span id='ddFileWithIdError' className='error-message' /></Label>
                <Select
                  className='select2-sm'
                  options={this.state.UserOptions}
                  isSearchable={true}
                  isClearable={false}
                  backspaceRemovesValue={false}
                  isRtl={false}
                  value={this.state.UserOptions.filter(option => option.value === this.state.fileWithId)}
                  onChange={e => { this.setState({ fileWithId: e.value }, () => fieldRequired(e.value, "ddFileWithIdError", "* Required")) }}
                />
              </div>

              <div className='mb-3 col-md-4'>
                <Label for='tbDateDue'>Date Due <span id='tbDateDueError' className='error-message' /></Label>
                <input id='tbDateDue' name='tbDateDue' className='form-control form-control-sm date-field' placeholder='Date Due' type="date" format='yyyy-MM-dd' defaultValue={moment(this.state.dateDue).format("yyyy-MM-DD")} onChange={e => this.setState({ dateDue: e.target.value })} />
              </div>
            </div>

          </ModalBody>
          <ModalFooter>
            <Button color="secondary" size="sm" onClick={this.toggleProgress}>Cancel <i className="far fa-times-circle ms-2"></i></Button>
            <Button color="primary" size="sm" onClick={this.saveProgress}>Save <i className="far fa-check-circle ms-2"></i></Button>
          </ModalFooter>
        </Modal>
        <ThrobbleHelper />
      </>
    );
  }

  saveProgress = async (event) => {
    event.stopPropagation();
    var valid = true;
    valid &= fieldRequired(this.state.taskId, 'tbTaskIdError', '* required');
    //valid &= fieldRequired(this.state.notes, 'tbNotesError', '* required');
    valid &= fieldRequired(this.state.fileWithId, 'ddFileWithIdError', '* required');
    valid &= fieldRequired(this.state.dateDue, 'tbDateDueError', '* required');

    if (valid) {

      ThrobbleHelper.ToggleThrobble(true, "Saving Task Item");
      var data = {
        Id: this.state.Id,
        CaseId: parseInt(this.state.CaseId),
        TaskId: this.state.taskId,
        TaskOther: this.state.taskOther,
        Notes: this.state.notes,
        FileWithId: this.state.fileWithId,
        DateDue: this.state.dateDue,
        ModifiedBy: getCurrentUser().id,
        DateAdded: this.state.DateAdded,
        DateModified: this.state.DateModified,
        Status: this.state.Status,
      }

      if (data.Id === 0)
        data.AddedBy = getCurrentUser().id;

      try {
        const response = await fetch('api/progress', {
          method: this.state.id === 0 ? 'POST' : 'PUT',
          withCredentials: true,
          credentials: 'include',
          headers: {
            'Authorization': `Bearer ${localStorage.getItem("token")}`,
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(data),
        });
        if (response.ok) {
          await response.json();
          this.loadData();
          this.setState({ progressModal: false });

          ThrobbleHelper.ToggleThrobble(false);
          showToast("success", "Save Task Item", "Task item was successfully saved");
        }
        else {
          console.log(response.status + ": " + response.statusText);
          if (response.status === 401) {
            removeLoginDetails();
            window.location.href = "/";
          } else {
            ThrobbleHelper.ToggleThrobble(false);
            showToast("error", "Save Task Item", "There was an error saving task item");
          }
        }

      } catch (e) {
        console.error(e);
        ThrobbleHelper.ToggleThrobble(false);
        showToast("error", "Save Task Item", "There was an error saving task item");
      }
    }


  }

  deleteProgressItem = async (id) => {
    const ConfirmProps = { DeleteId: id, Title: "Delete Task Item", Message: "Are you sure you want to delete this task item?" };
    confirmAlert({
      customUI: ({ onClose }) => <ConfirmHelper {...ConfirmProps} onClose={onClose} onConfirm={this.deleteData} />
    })
  }

  async deleteData(id) {
    try {
      ThrobbleHelper.ToggleThrobble(true, "Deleting Progress Item")
      const response = await fetch('api/progress/' + id, {
        method: "DELETE",
        headers: {
          "Authorization": `Bearer ${localStorage.getItem("token")}`,
          "Content-Type": "application/json"
        }
      })

      if (response.ok) {
        this.loadData();
        ThrobbleHelper.ToggleThrobble(false);
        showToast("success", "Delete Task Item", "Task item was successfully deleted");
      } else {
        if (response.status === 401) {
          removeLoginDetails();
          window.location.href = "/";
        } else {
          ThrobbleHelper.ToggleThrobble(false);
          showToast("error", "Delete Task Item", "There was an error deleting task item");
        }
      }
    } catch (error) {
      console.error(error);
      ThrobbleHelper.ToggleThrobble(false);
      showToast("error", "Delete Task Item", "There was an error deleting task item");
    }
  }

}

export default ProgressTasks;