import { DateRangePickerComponent } from '@syncfusion/ej2-react-calendars';
import { ColumnDirective, ColumnsDirective, CommandColumn, GridComponent, ExcelExport, Inject, Sort, Toolbar } from '@syncfusion/ej2-react-grids';
import moment from 'moment';
import React, { Component } from 'react';
import { Button, Card, CardBody, Col, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap';
import { DatePresets } from '../../helpers/global';
import { removeLoginDetails } from '../../helpers/Lookup';
import ThrobbleHelper from '../../helpers/ThrobbleHelper';
import { showToast } from '../../helpers/ToastHelper';
import { Graph } from '../LineChart';


export default class Outcomes extends Component {
  constructor(props) {
    super(props);
    this.state = {
      cases: [],
      ReportStartDate: new Date(moment().add(-30, "days").format("yyyy-MM-DD HH:mm:ss")),
      ReportEndDate: new Date(moment().format("yyyy-MM-DD HH:mm:ss")),
      orderBy: "outcomeDown",
      hideThrobble: false,
      caseFiles: [],
      gridData: [],
      filteredCases: [],
      viewModal: false,
      exportName: ""
    };
    this.ExportReport = this.ExportReport.bind(this);
    this.toggle = this.toggle.bind(this);
  }

  toggle() {
    this.setState({
      viewModal: !this.state.viewModal
    });
  }

  componentDidMount() {
    this.loadCases();
  }

  onGridCommand = (args) => {
    switch (args.commandColumn.type) {

      default: //view
        
        const gridData = this.state.caseFiles.filter(item => {
          return item.outcomeId === args.rowData.id;
        });
        this.setState({ gridData: gridData, exportName: args.rowData.x, viewModal: true });
        break;
    }
  }

  padNumber = (number) => {
    return number.toString().padStart(5, '0');
  };

  updateDateFilter = (update) => {
    this.setState({ ReportStartDate: update.startDate, ReportEndDate: update.endDate }, () => this.state.ReportEndDate && this.loadCases())
  }

  excelQueryCellInfo = (args) => {

    if (args.column.field === 'caseNumber') {
      args.value = this.padNumber(args.data['caseNumber'])
    }
    if (args.column.field === 'dateOpened') {
      args.value = moment(args.data['dateOpened']).format("yyyy-MM-DD");
    }
    if (args.column.field === 'dateClosed') {
      args.value = moment(args.data['dateClosed']).format("yyyy-MM-DD");
    }
    if (args.column.field === 'dateResolved') {
      if (args.data['dateResolved'])
        args.value = moment(args.data['dateResolved']).format("yyyy-MM-DD");
      else
        args.value = "Not Specified";
    }
  }

  exportReport = (grid, headerText) => {
    const excelExportColumns = [
      { field: 'caseNumber', headerText: "File #", type: 'text', width: 90 },
      { field: 'complainant', headerText: 'Complainant Name', width: 240 },
      { field: 'respondent', headerText: 'Respondent Name', width: 240 },
      { field: 'dateOpened', textAlign: 'Center', headerText: "Date Opened", width: 120 },
      { field: 'dateClosed', textAlign: 'Center', headerText: "Date Closed", width: 120 },
      { field: 'dateResolved', textAlign: 'Center', headerText: "Date Resolved", width: 120 },
      { field: 'outcomeName', headerText: "Outcome", width: 300 },

    ];

    const excelExportProperties = {
      fileName: headerText + '.xlsx',
      columns: excelExportColumns,
      header: {
        headerRows: 1,
        rows: [
          { cells: [{ colSpan: 5, value: headerText, style: { fontColor: '#201d39', fontSize: 16, hAlign: 'Center', bold: true, } }] },
        ]
      }
    };
    grid.excelExport(excelExportProperties);
  }


  render() {
    let grid;
    

    const commandTemplate = [
      { type: 'View Case Files', buttonOption: { cssClass: 'e-flat', iconCss: 'e-eye e-icons' } }
    ];

    return (
      <div>
        <div className='dms-header d-flex justify-content-center p-3 align-items-center '>
          <h1 style={{ fontSize: "30px", fontWeight: 100 }}>COMPLAINTS BY OUTCOMES</h1>
        </div>
        <Card className='my-3'>
          <CardBody>
            <div className="row">
              <div className="col-md-3">
                <Label>Filter By Date Range</Label><br />
                <DateRangePickerComponent id="daterangepicker" placeholder='Select a range' separator='to' startDate={this.state.ReportStartDate} endDate={this.state.ReportEndDate} presets={DatePresets} format='yyyy-MM-dd' change={this.updateDateFilter} />
              </div>
              <div className="col-md-9 align-self-end text-end">
                <Button color='primary' onClick={this.ExportReport}><i className='far fa-file-excel me-2'></i>Export Report</Button>
              </div>
            </div>
          </CardBody>
        </Card>
        <Card>
          <CardBody>
            <Row className='my-5 align-items-center'>
              <Col md={12}>
                <div className='text-center'>
                  <h4>Summary View</h4>
                  <Graph type={"pie"} data={this.state.filteredCases.sort((a, b) => b.y - a.y).slice(0,10)} />
                </div>
                <div className='text-center mt-5'>
                  <h4>Detailed View</h4>
                  <Graph type={"bar"} data={this.state.cases} />
                </div>
              </Col>

            </Row>
            
            <GridComponent dataSource={this.state.cases} allowSorting={true} toolbar={['Search']} commandClick={this.onGridCommand}>
              <ColumnsDirective>
                <ColumnDirective field='x' width='100' headerText="Outcome" />
                <ColumnDirective field='y' width='20' headerText="Number of cases" textAlign="center" />
                <ColumnDirective headerText='Actions' width='20' commands={commandTemplate} />
              </ColumnsDirective>
              <Inject services={[Sort, CommandColumn, Toolbar]} />
            </GridComponent>
          </CardBody>
        </Card>

        <Modal isOpen={this.state.viewModal} toggle={this.toggle} className={this.props.className} scrollable size="xl" backdrop="static">
          <ModalHeader toggle={this.toggle} close={<button className="close" onClick={this.toggle}><i className="fas fa-times"></i></button>}>Case Files - Outcome - {this.state.exportName}</ModalHeader>
          <ModalBody>
            <GridComponent id='Grid' ref={g => grid = g} dataSource={this.state.gridData} allowSorting={true} allowExcelExport={true} excelQueryCellInfo={this.excelQueryCellInfo} >
              <ColumnsDirective>
                <ColumnDirective field='caseNumber' width='40' headerText="File #" template={(props) => this.padNumber(props.caseNumber)} />
                <ColumnDirective field='complainant' width='100' headerText="Complainant Name" />
                <ColumnDirective field='respondent' width='100' headerText="Respondent Name" />
                <ColumnDirective field='dateOpened' width='50' headerText="Date Opened" type="date" format='y/MM/dd' textAlign='center' />
                <ColumnDirective field='dateClosed' width='50' headerText="Date Closed" type="date" format='y/MM/dd' textAlign='center' />
                <ColumnDirective field='dateResolved' width='50' headerText="Date Resolved" type="date" format='y/MM/dd' textAlign='center' />
                <ColumnDirective field='outcomeName' width='100' headerText="Outcome" />
              </ColumnsDirective>
              <Inject services={[Sort, ExcelExport]} />
            </GridComponent>

          </ModalBody>
          <ModalFooter>
            <Button color="primary" outline size="sm" onClick={() => { if (grid) this.exportReport(grid, "Complaints By Outcome - " + this.state.exportName); }}>Export <i className="far fa-file-excel ms-2"></i></Button>
            <Button color="dark" size="sm" onClick={this.toggle}>Close <i className="far fa-times-circle ms-2"></i></Button>

          </ModalFooter>
        </Modal>
        <ThrobbleHelper />
      </div>
    )
  }

  async ExportReport(evt) {
    evt.stopPropagation();
    ThrobbleHelper.ToggleThrobble(true, "Exporting Outcomes Report");
    const startDate = moment(this.state.ReportStartDate).format("yyyy-MM-DD");
    const endDate = moment(this.state.ReportEndDate).format("yyyy-MM-DD");
    try {
      const response = await fetch("api/reports/GenerateExcelOutcomesReport/" + startDate + "/" + endDate, {
        method: "GET",
        headers: {
          "Authorization": "Bearer " + localStorage.getItem("token"),
          "Content-Type": "application/pdf"
        }
      })

      if (response.ok) {
        const data = await response.blob();
        const contentDisposition = response.headers.get("content-disposition");
        const fileNameMatch = contentDisposition.match(/filename=(.*?);/);
        const fileName = fileNameMatch
          ? fileNameMatch[1].replace(/"/g, "")
          : "unknown";

        // Create a URL from the response blob
        const fileUrl = URL.createObjectURL(data);
        const LinkBtn = document.createElement("a");
        LinkBtn.download = fileName;
        LinkBtn.href = fileUrl;
        LinkBtn.click();

        ThrobbleHelper.ToggleThrobble(false);
        showToast("success", "Report Export", "Outcomes report was successfully exported");
      } else {
        if (response.status === 401) {
          removeLoginDetails();
          window.location.href = "/";
        } else {
          ThrobbleHelper.ToggleThrobble(false);
          showToast("error", "Report Export", "There was an error exporting outcomes report");
        }
      }
    }
    catch (e) {
      console.log(e);
      ThrobbleHelper.ToggleThrobble(false);
      showToast("error", "Report Export", "There was an error exporting outcomes report");
    }

  }

  async loadCases() {
    try {
      ThrobbleHelper.ToggleThrobble(true, "Loading Outcomes Report");
      const startDate = moment(this.state.ReportStartDate).format("yyyy-MM-DD");
      const endDate = moment(this.state.ReportEndDate).format("yyyy-MM-DD");
      const response = await fetch("api/reports/GetOutcomesReport/" + startDate + "/" + endDate, {
        method: "GET",
        headers: {
          "Authorization": "Bearer " + localStorage.getItem("token"),
          "Content-Type": "application/json"
        }
      })

      if (response.ok) {
        let cases = await response.json();
        console.log(cases);

        let filteredCases = cases.item1.filter(item => !item.x.startsWith("1st L") && item.y);

        let groupedItem = {
          id: "grouped",
          x: "1st L - grouped",
          color: "#e6194b",
          y: 0
        };

        cases.item1.forEach(item => {
          if (item.x.startsWith("1st L")) {
            groupedItem.y += item.y;
          }
        });

        filteredCases.push(groupedItem);

        //filteredCases.push(cases.item1.filter(item => !item.x.startsWith("1st L")));

        console.log(filteredCases);
        this.setState({ cases: cases.item1, caseFiles: cases.item2, filteredCases: filteredCases })
        ThrobbleHelper.ToggleThrobble(false);
        //this.setState({ cases }, () => ThrobbleHelper.ToggleThrobble(false));

      } else {
        if (response.status === 401) {
          removeLoginDetails();
          window.location.href = "/";
        } else
          ThrobbleHelper.ToggleThrobble(false);
      }
    } catch (e) {
      ThrobbleHelper.ToggleThrobble(false);
      console.error(e);
    }
  }
}
