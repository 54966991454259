export const fieldRequired = (data, errorContainer, errorMessage) => {
  if (!data) {
    //document.getElementById(errorContainer).innerText = errorMessage;
    const el = document.getElementById(errorContainer);
    el.setAttribute("tabindex", "1");
    el.focus();
    el.innerText = errorMessage;
    return false;
  }
  else {
    document.getElementById(errorContainer).innerText = "";
    return true;
  }
};

export const colorValidator = (color, errorContainer, errorMessage) => {
  if (color === '' || color === 'FFFFFF00') {
    //document.getElementById(errorContainer).innerText = errorMessage;
    const el = document.getElementById(errorContainer);
    el.setAttribute("tabindex", "1");
    el.focus();
    el.innerText = errorMessage;
    return false;
  }
  else {
    document.getElementById(errorContainer).innerText = "";
    return true;
  }
}